<template>
  <v-dialog v-model="dialog" max-width="1000" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Product RR</span>
      </v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="items" disable-pagination hide-default-footer>
          <template v-slot:item.no="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:item.partDesc="{ item }">
            {{ item.products ? item.products.part_desc : "" }}
          </template>
          <template v-slot:item.partNumber="{ item }">
            {{ item.products ? item.products.part_num : "" }}
          </template>
          <template v-slot:item.category="{ item }">
            {{ item.products ? categoryText(item.products.category_part_num) : "" }}
          </template>
          <template v-slot:item.unitMeasure="{ item }">
            {{ item.products ? item.products.default_um : "" }}
          </template>
          <template v-slot:item.unitPrice="{ item }">
            {{ item.po_detail ? formatPrice(item.po_detail.unit_price) : 0 }}
          </template>
          <template v-slot:item.rate="{ item }">
            {{ item.po_detail ? formatPrice(item.po_detail.rate) : 0 }}
          </template>
          <template v-slot:item.unitPriceIdr="{ item }">
            {{ item.po_detail ? formatPrice(item.po_detail.unit_price * item.po_detail.rate) : 0 }}
          </template>
          <template v-slot:body.append="{}">
            <tr>
              <td colspan="6">TOTAL</td>
              <td>
                {{ formatPrice(total) }}
              </td>
            </tr>
            <tr>
              <td colspan="6">TOTAL IDR</td>
              <td>
                {{ formatPrice(totalIdr) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="close" class="mr-2">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "product-rr",
  props: {
    dialog: Boolean,
    items: Array,
  },
  data() {
    return {
      headers: [
        {
          text: "No",
          value: "no",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Part#",
          value: "partNumber",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Product Detail",
          value: "partDesc",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Category",
          value: "category",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Qty",
          value: "qty_receive",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "U/M",
          value: "unitMeasure",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "U/Price",
          value: "unitPrice",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        // {
        //   text: "Rate",
        //   value: "rate",
        //   sortable: false,
        //   divider: true,
        //   class: "light-blue lighten-2 font-weight-black",
        // },
        // {
        //   text: "U/Price IDR",
        //   value: "unitPriceIdr",
        //   sortable: false,
        //   divider: true,
        //   class: "light-blue lighten-2 font-weight-black",
        // },
      ],
      listCategory: [
        { value: "0", text: "Material" },
        { value: "1", text: "Consumable" },
        { value: "2", text: "Finished Goods" },
        { value: "3", text: "Service" },
        { value: "4", text: "Supply" },
      ],
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(3).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    categoryText(item) {
      return this.listCategory.find(x => x.value === item).text;
    },
  },
  computed: {
    total() {
      let total = 0;
      this.items.forEach(x => {
        total += parseFloat(x.qty_receive) * parseFloat(x.po_detail.unit_price);
      });
      return total;
    },
    totalIdr() {
      let total = 0;
      this.items.forEach(x => {
        total +=
          parseFloat(x.qty_receive) *
          parseFloat(x.po_detail.unit_price.toFixed(2)) *
          x.po_detail.rate;
      });
      return total;
    },
  },
};
</script>

<style></style>
