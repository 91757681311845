<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Vendor <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  :items="vendors"
                  item-text="supplier_name"
                  item-value="id"
                  v-model="form.vendorId"
                  disabled
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>PO Vendor <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-textarea
                  outlined
                  flat
                  rows="3"
                  background-color="white"
                  v-model="form.purchaseOrderNumber"
                  disabled
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Invoice Number <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.invoiceNumber"
                  :rules="[v => !!v || 'Invoice Number is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Invoice Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuInvoiceDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.invoiceDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.invoiceDate"
                    :allowed-dates="getAllowedDates()"
                    @input="menuInvoiceDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Received Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuInvoiceReceivedDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.invoiceReceivedDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.invoiceReceivedDate"
                    @input="menuInvoiceReceivedDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader> Percent DP<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.percentageDownPayment"
                  suffix="%"
                  @input="onInputDownPayment"
                  :rules="[
                    v =>
                      (v <= 100 && v >= 1) || 'Percentage DP must be more than 1 and less than 100',
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader> Percent PPN<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.percentagePpn"
                  suffix="%"
                  @input="onInputPpn"
                  :rules="[v => v >= 0 || 'PPN must more equals 0']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader> Term <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.invoiceDuration"
                  suffix="days"
                  @input="onInputInvoiceDuration"
                  :rules="[v => v >= 0 || 'Term must more than equals 0']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Due Date<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuInvoiceDueDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.invoiceDueDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      disabled
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.invoiceDueDate"
                    @input="menuInvoiceDueDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" v-if="form.percentagePpn > 0">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Tax Invoice Serial Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.taxInvoiceSerialNumber"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Remark</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-textarea
                  outlined
                  flat
                  rows="3"
                  background-color="white"
                  v-model="form.remark"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>PPH Account</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.pphName"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Tax Date</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuTaxDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.taxDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.taxDate"
                    @input="menuTaxDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>DPP</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.totalDpp"
                  type="number"
                  persistent-hint
                  :hint="formatPrice(form.totalDpp)"
                  :rules="[v => v >= 0 || 'DPP must more than equal 0']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab>
                <v-icon>
                  mdi-information
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6"></v-col>
                    <v-col cols="6" class="text-right pb-0">
                      <p class="headline font-italic">
                        Item Detail <span class="red--text">*</span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="form.products"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickProduct"
                      >
                        <template v-slot:item.no="{ index }">
                          {{ index + 1 }}
                        </template>
                        <template v-slot:item.unitPrice="{ item }">
                          {{ formatPrice(item.unitPrice) }}
                        </template>
                        <template v-slot:item.total="{ item }">
                          {{ formatPrice(item.total) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-title class="font-italic">Additional Info</v-card-title>
                  <v-row>
                    <!-- <v-col cols="4" sm="4" md="2" lg="2" class="pb-0">
                      <v-subheader>Due Date</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="pb-0">
                      <span>{{ form.invoiceDueDate }}</span>
                    </v-col> -->
                    <v-col cols="4" sm="4" md="2" lg="2" class="pb-0">
                      <v-subheader>Currency</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4">
                      <span>{{ form.currency }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Percent Up To This Invoice</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4">
                      <span>{{ form.percentageUpTo }}</span>
                    </v-col>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Bill To</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4">
                      <span>{{ form.billTo }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>FOB</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4">
                      <span>{{ form.fob }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Address</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4">
                      <span>{{ form.address }}</span>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total PO</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalPoAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total Inv</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Discount</v-card-title>
              <v-card-text class="text-right mt-3">
                {{ formatPrice(form.discountAmount) }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total After Disc</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmountAfterDisc) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2" v-if="form.percentagePpn > 0">
            <v-card outlined>
              <v-card-title class="py-0">Tax</v-card-title>
              <v-card-text class="text-right mt-3">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.taxAmount"
                  @change="onInputTax"
                  persistent-hint
                  :hint="formatPrice(form.taxAmount)"
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Pph</v-card-title>
              <v-card-text class="text-right mt-3">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.pphAmount"
                  @change="onInputTax"
                  persistent-hint
                  :hint="formatPrice(form.pphAmount)"
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total After Tax</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmountAfterTax) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-product
      :dialog="dialog"
      :item="item"
      :index="index"
      @close="close"
      @save="save"
      @deleteItem="deleteItem"
    ></dialog-product>
    <dialog-review-journal
      :dialog="dialogReview"
      :journals="journals"
      @save="update"
      @close="close"
      :currency="form.currency"
    ></dialog-review-journal>
  </v-form>
</template>

<script>
import moment from "moment";
import DialogProduct from "@/components/DialogProduct";
import DialogReviewJournal from "@/components/DialogReviewJournal";
import { fiscalMonthMixin } from "@/services/mixins/fiscalMonthMixin";

export default {
  name: "form-purchase-down-payment",
  mixins: [fiscalMonthMixin],
  components: {
    "dialog-product": DialogProduct,
    DialogReviewJournal,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    vendors: {
      type: Array,
      default: () => [],
    },
    purchaseOrders: {
      type: Array,
      default: () => [],
    },
    headers: Array,
  },
  data: () => ({
    menuInvoiceDate: false,
    menuInvoiceDueDate: false,
    menuInvoiceReceivedDate: false,
    menuTaxDate: false,
    item: {},
    index: -1,
    dialog: false,
    dialogReview: false,
    valid: true,
    journals: [],
  }),

  computed: {
    totalAmount() {
      let subtotal = 0;
      this.form.products.map(key => {
        subtotal += key.qty * key.unitPrice;
      });
      return subtotal;
    },
  },

  watch: {
    totalAmount(val) {
      this.form.totalPoAmount = val;
      this.form.totalPoAmountIDR = val * this.form.rate;
      this.form.totalPoAfterDiscountAmount = val - this.form.totalPoDiscountAmount;
      this.form.totalPoAfterDiscountAmountIDR =
        this.form.totalPoAfterDiscountAmount * this.form.rate;
      if (this.form.currency != "IDR") {
        this.form.totalAmount = this.form.totalPoAmount * (this.form.percentageDownPayment / 100);
        this.form.discountAmount =
          this.form.totalPoDiscountAmount * (this.form.percentageDownPayment / 100);
        this.form.totalAmountAfterDisc = this.form.totalAmount - this.form.discountAmount;
        this.form.totalAmountAfterTax = this.form.totalAmountAfterDisc + this.form.taxAmount;
      } else {
        this.form.totalAmount = parseFloat(
          (this.form.totalPoAmount * (this.form.percentageDownPayment / 100)).toFixed(0)
        );
        this.form.discountAmount = parseFloat(
          (this.form.totalPoDiscountAmount * (this.form.percentageDownPayment / 100)).toFixed(0)
        );
        this.form.totalAmountAfterDisc = parseFloat(
          (this.form.totalAmount - this.form.discountAmount).toFixed(0)
        );
        this.form.totalAmountAfterTax = parseFloat(
          (this.form.totalAmountAfterDisc + this.form.taxAmount).toFixed(0)
        );
      }
    },
  },

  methods: {
    checkJournal() {
      if (this.$refs.form.validate() && this.form.products.length > 0) {
        this.journals = [
          {
            accountName: `UANG MUKA PEMBELIAN ${this.form.currency}`,
            debit: this.form.totalAmountAfterDisc,
            credit: 0,
            debitIDR: this.form.totalAmountAfterDiscIDR,
            creditIDR: 0,
          },
          {
            accountName: `${this.form.vendorName} ${this.form.currency}`,
            debit: 0,
            credit: this.form.totalAmountAfterTax,
            debitIDR: 0,
            creditIDR: this.form.totalAmountAfterTaxIDR,
          },
        ];
        if (this.form.taxAmount > 0) {
          this.journals.unshift({
            accountName: `PAJAK MASUKAN`,
            debit: this.form.taxAmount,
            credit: 0,
            debitIDR: this.form.taxAmountIDR,
            creditIDR: 0,
          });
        }
        if (this.form.pphAmount > 0) {
          this.journals.push({
            accountName: this.form.pphName,
            debit: 0,
            credit: this.form.pphAmount,
            debitIDR: 0,
            creditIDR: this.form.pphAmount,
          });
        }
        this.dialogReview = true;
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Items is required",
        });
      }
    },
    update() {
      let totalDebit = this.form.totalAmountAfterDisc - this.form.pphAmount + this.form.taxAmount;
      let totalCredit = this.form.totalAmountAfterTax;

      if (totalDebit.toFixed(2) - totalCredit.toFixed(2) === 0) {
        this.$store.commit("SET_LOADER", true);
        this.$store
          .dispatch("purchaseDownPayment/update", this.form)
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      } else {
        let different = totalDebit - totalCredit;
        if (different > 0) {
          this.$store.dispatch("toast", {
            type: "error",
            message: `Journal not balance with -${Math.abs(different)} on Debit`,
          });
        } else {
          this.$store.dispatch("toast", {
            type: "error",
            message: `Journal not balance with -${Math.abs(different)} on Dredit`,
          });
        }
      }
    },
    invoiceDueDate() {
      let dueDate = moment(this.form.invoiceReceivedDate)
        .add(this.form.invoiceDuration, "days")
        .format("yyyy-MM-DD");
      return dueDate;
    },
    onInputInvoiceDuration() {
      this.form.invoiceDueDate = this.invoiceDueDate();
    },
    onInputDownPayment() {
      if (this.form.currency != "IDR") {
        this.form.totalAmount = this.form.totalPoAmount * (this.form.percentageDownPayment / 100);
        this.form.discountAmount =
          this.form.totalPoDiscountAmount * (this.form.percentageDownPayment / 100);
        this.form.totalAmountAfterDisc = this.form.totalAmount - this.form.discountAmount;
        this.form.totalAmountAfterTax = this.form.totalAmountAfterDisc + this.form.taxAmount;
      } else {
        this.form.totalAmount = parseFloat(
          (this.form.totalPoAmount * (this.form.percentageDownPayment / 100)).toFixed(0)
        );
        this.form.discountAmount = parseFloat(
          (this.form.totalPoDiscountAmount * (this.form.percentageDownPayment / 100)).toFixed(0)
        );
        this.form.totalAmountAfterDisc = parseFloat(
          (this.form.totalAmount - this.form.discountAmount).toFixed(0)
        );
        this.form.totalAmountAfterTax = parseFloat(
          (this.form.totalAmountAfterDisc + this.form.taxAmount).toFixed(0)
        );
      }
      this.form.percentageUpTo = this.form.percentageDownPayment;
    },
    onInputPpn() {
      if (this.form.currency != "IDR") {
        this.form.taxAmount = this.form.totalAmountAfterDisc * (this.form.percentagePpn / 100);
      } else {
        this.form.taxAmount = parseFloat(
          (this.form.totalAmountAfterDisc * (this.form.percentagePpn / 100)).toFixed(0)
        );
      }
      this.form.totalAmountAfterTax = this.form.totalAmountAfterDisc + this.form.taxAmount;
    },
    onInputTax() {
      this.form.taxAmountIDR = this.form.taxAmount * this.form.rate;
      this.form.pphAmountIDR = this.form.pphAmount * this.form.rate;
      this.form.totalAmountAfterTax =
        this.form.totalAmountAfterDisc - this.form.pphAmount + this.form.taxAmount;
      this.form.totalAmountAfterTaxIDR =
        this.form.totalAmountAfterDiscIDR - this.form.pphAmountIDR + this.form.taxAmountIDR;
    },
    deleteItem(index) {
      if (index > -1) {
        this.form.products.splice(index, 1);
        this.dialog = false;
      }
    },
    close() {
      this.dialog = this.dialogReview = false;
    },
    save(item) {
      this.form.products[this.index] = item;
    },
    onClickProduct(item) {
      this.item = item;
      this.index = this.form.products.map(x => x.productId).indexOf(item.productId);
      this.dialog = true;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
