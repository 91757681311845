<template>
  <v-row>
    <v-col cols="12" sm="12" md="12" lg="12">
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Vendor <span class="red--text">*</span></v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ item.vendorName }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>PO Vendor <span class="red--text">*</span></v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ item.purchaseOrderNumber }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Invoice Number <span class="red--text">*</span></v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ item.invoiceNumber }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Invoice Date <span class="red--text">*</span></v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ formatDate(item.invoiceDate) }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Received Date <span class="red--text">*</span></v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ formatDate(item.invoiceReceivedDate) }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader> Percent DP<span class="red--text">*</span></v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ item.percentageDownPayment }}%</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader> Percent PPN<span class="red--text">*</span></v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ item.percentagePpn }}%</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader> Term <span class="red--text">*</span></v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ item.invoiceDuration }} days</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Due Date<span class="red--text">*</span></v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ item.invoiceDueDate }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6" v-if="item.percentagePpn > 0">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Tax Invoice Serial Number</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ item.taxInvoiceSerialNumber }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Remark</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ item.remark }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>PPH Account</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ item.pphName }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Tax Date</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ item.taxDate }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>DPP</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ formatPrice(item.totalDpp) }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-tabs vertical>
            <v-tab background-color="light-blue accent-3" dark class="px-0">
              <v-icon>
                mdi-note-text
              </v-icon>
            </v-tab>
            <v-tab>
              <v-icon>
                mdi-information
              </v-icon>
            </v-tab>
            <v-tab-item>
              <v-card flat class="px-2">
                <v-row>
                  <v-col cols="6"></v-col>
                  <v-col cols="6" class="text-right pb-0">
                    <p class="headline font-italic">Item Detail <span class="red--text">*</span></p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-data-table
                      :headers="headers"
                      :items="item.products"
                      disable-pagination
                      hide-default-footer
                    >
                      <template v-slot:item.no="{ index }">
                        {{ index + 1 }}
                      </template>
                      <template v-slot:item.unitPrice="{ item }">
                        {{ formatPrice(item.unitPrice) }}
                      </template>
                      <template v-slot:item.total="{ item }">
                        {{ formatPrice(item.total) }}
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-title class="font-italic">Additional Info</v-card-title>
                <v-row>
                  <!-- <v-col cols="4" sm="4" md="2" lg="2" class="pb-0">
                    <v-subheader>Due Date</v-subheader>
                  </v-col>
                  <v-col cols="8" sm="8" md="4" lg="4" class="pb-0">
                    <span>{{ formatDate(item.invoiceDueDate) }}</span>
                  </v-col> -->
                  <v-col cols="4" sm="4" md="2" lg="2" class="pb-0">
                    <v-subheader>Currency</v-subheader>
                  </v-col>
                  <v-col cols="8" sm="8" md="4" lg="4">
                    <span>{{ item.currency }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                    <v-subheader>Percent Up To This Invoice</v-subheader>
                  </v-col>
                  <v-col cols="8" sm="8" md="4" lg="4">
                    <span>{{ item.percentageUpTo }}%</span>
                  </v-col>
                  <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                    <v-subheader>Bill To</v-subheader>
                  </v-col>
                  <v-col cols="8" sm="8" md="4" lg="4">
                    <span>{{ item.billTo }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                    <v-subheader>FOB</v-subheader>
                  </v-col>
                  <v-col cols="8" sm="8" md="4" lg="4">
                    <span>{{ item.fob }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                    <v-subheader>Address</v-subheader>
                  </v-col>
                  <v-col cols="8" sm="8" md="4" lg="4">
                    <span>{{ item.address }}</span>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="2">
          <v-card outlined>
            <v-card-title class="py-0">Total PO</v-card-title>
            <v-card-text class="text-right mt-3">
              <span>{{ formatPrice(item.totalPoAfterDiscountAmount) }}</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card outlined>
            <v-card-title class="py-0">Total Inv</v-card-title>
            <v-card-text class="text-right mt-3">
              <span>{{ formatPrice(item.totalAmount) }}</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card outlined>
            <v-card-title class="py-0">Discount</v-card-title>
            <v-card-text class="text-right mt-3">
              <span>{{ formatPrice(item.discountAmount) }}</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2" v-if="item.percentagePpn > 0">
          <v-card outlined>
            <v-card-title class="py-0">Tax</v-card-title>
            <v-card-text class="text-right mt-3">
              <span>{{ formatPrice(item.taxAmount) }}</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card outlined>
            <v-card-title class="py-0">Pph</v-card-title>
            <v-card-text class="text-right mt-3">
              <span>{{ formatPrice(item.pphAmount) }}</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card outlined>
            <v-card-title class="py-0">Total After Disc</v-card-title>
            <v-card-text class="text-right mt-3">
              <span>{{ formatPrice(item.totalAmountAfterDisc) }}</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card outlined>
            <v-card-title class="py-0">Total After Tax</v-card-title>
            <v-card-text class="text-right mt-3">
              <span>{{ formatPrice(item.totalAmountAfterTax) }}</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
export default {
  name: "detail-purchase-down-payment",
  props: {
    item: {
      type: Object,
      default: () => undefined,
    },
    purchaseOrders: {
      type: Array,
      default: () => [],
    },
    headers: Array,
  },
  data: () => ({}),

  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    purchaseOrderName(purchaseOrderId) {
      const purchaseOrder = this.purchaseOrders.find(x => x.id === purchaseOrderId);
      const result = purchaseOrder ? purchaseOrder.name : "";
      return result;
    },
    index(id) {
      const result = this.item.products.map(x => x.id).indexOf(id);
      return result;
    },
    formatDate(date) {
      return moment(date).format("DD-MM-yyyy");
    },
  },
};
</script>

<style></style>
