<template>
  <form>
    <v-row class="py-0">
      <v-col class="py-0" cols="12" sm="12" md="11" lg="11">
        <form-edit
          :form="purchaseDownPayment"
          :vendors="vendors"
          :purchaseOrders="purchaseOrders"
          :headers="headers"
          ref="formEdit"
          v-if="!edit"
        ></form-edit>
        <detail
          :id="id"
          :item="purchaseDownPayment"
          :purchaseOrders="purchaseOrders"
          :headers="headers"
          v-if="edit"
        ></detail>
      </v-col>
      <v-col cols="12" sm="12" md="1">
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="updateItem">
                  <v-icon>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="printJournal">
                  <v-icon>
                    mdi-printer
                  </v-icon>
                </v-btn>
              </template>
              <span>Journal</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="createJournalManual">
                  <v-icon>
                    mdi-notebook
                  </v-icon>
                </v-btn>
              </template>
              <span>Create Journal Manual</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit && !purchaseDownPayment.linkRr">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="warning" v-on="on" @click="openLinkRR">
                  <v-icon>
                    mdi-link
                  </v-icon>
                </v-btn>
              </template>
              <span>Link RR</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="warning" v-on="on" @click="openUpdateTaxDate">
                  <v-icon>
                    mdi-update
                  </v-icon>
                </v-btn>
              </template>
              <span>Update Tax Date</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit && purchaseDownPayment.status === 'Outstanding'">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="editItem">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit && purchaseDownPayment.status === 'Outstanding'">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click.stop="deleteDialog">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="cancel">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="back">
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-delete
      :id="id"
      :dialog="dialog"
      :invoiceNumber="purchaseDownPayment.invoiceNumber"
      title="Purchase Down Payment"
      @deleteItem="deleteItem"
      @close="close"
    ></dialog-delete>
    <link-rr
      :dialog="dialogLinkRr"
      :id="id"
      :deliveryOrders="deliveryOrders"
      @close="close"
    ></link-rr>
    <dialog-update-tax-date
      :dialog="dialogTax"
      :form="purchaseDownPayment"
      @save="updateTaxDate"
      @close="close"
    ></dialog-update-tax-date>
  </form>
</template>

<script>
import Detail from "./DetailPurchaseDownPayment";
import FormEdit from "./FormPurchaseDownPayment";
import DialogDelete from "@/components/DialogDelete";
import LinkRr from "./LinkRr.vue";
import { mapState } from "vuex";
import DialogUpdateTaxDate from "@/components/DialogUpdateTaxDate.vue";

export default {
  name: "purchase-down-payment",
  components: {
    Detail,
    "form-edit": FormEdit,
    "dialog-delete": DialogDelete,
    LinkRr,
    DialogUpdateTaxDate,
  },
  props: {
    id: {
      type: Number,
      default: () => undefined,
    },
  },
  data: () => ({
    edit: true,
    dialog: false,
    dialogLinkRr: false,
    dialogTax: false,
    purchaseOrders: [],
    deliveryOrders: [],
    purchaseDownPayment: {},
    headers: [],
  }),
  computed: {
    ...mapState("external", ["vendors"]),
  },
  methods: {
    async getPurchaseDownPayment() {
      await this.$store.dispatch("purchaseDownPayment/getById", this.id).then(response => {
        this.purchaseDownPayment = response.data;
        this.changeHeader(response.data.currency);
        this.getDeliveryOrders(response.data.purchaseOrders[0].id);
      });
    },
    back() {
      this.$router.go(-1);
    },
    printJournal() {
      this.$router.push({ name: "Journal Purchase Down Payment", params: { id: this.id } });
    },
    editItem() {
      this.edit = false;
    },
    updateItem() {
      this.$refs.formEdit.checkJournal();
    },
    deleteItem(id) {
      this.$store.dispatch("purchaseDownPayment/delete", id);
      this.dialog = false;
    },
    deleteDialog() {
      this.dialog = true;
    },
    close() {
      this.dialog = this.dialogLinkRr = this.dialogTax = false;
    },
    cancel() {
      this.getPurchaseDownPayment();
      this.edit = true;
    },
    openLinkRR() {
      this.dialogLinkRr = true;
    },
    openUpdateTaxDate() {
      this.dialogTax = true;
    },
    async updateTaxDate(item) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("purchaseDownPayment/updateTaxDate", item)
        .then(() => {
          this.$store.commit("SET_LOADER", false);
          window.location.reload();
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async getPurchaseOrders() {
      const response = await this.$store.dispatch("external/getPurchaseOrdersById", {
        id: this.id,
        type: "DP",
      });
      if (response.status === 200) {
        this.purchaseOrders = response.data;
      }
    },
    async getDeliveryOrders(id) {
      const response = await this.$store.dispatch("external/getDeliveryOrders", id);
      if (response.status === 200) {
        this.deliveryOrders = response.data;
      }
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        this.getPurchaseDownPayment(),
        this.getPurchaseOrders(),
        await this.$store.dispatch("external/getVendors"),
      ])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async createJournalManual() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("purchaseDownPayment/createJournalManual", this.id)
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    changeHeader(currency) {
      this.headers = [
        {
          text: "No",
          value: "no",
          sortable: false,
          divider: true,
        },
        {
          text: "Product Detail",
          value: "productDetail",
          sortable: false,
          divider: true,
        },
        {
          text: "Qty",
          value: "qty",
          sortable: false,
          divider: true,
        },
        {
          text: "U/M",
          value: "unitMeasure",
          sortable: false,
          divider: true,
        },
        {
          text: "U/Price",
          value: "unitPrice",
          sortable: false,
          divider: true,
        },
        {
          text: "Disc %",
          value: "discount",
          sortable: false,
          divider: true,
        },
        {
          text: `Amount (${currency})`,
          value: "total",
          sortable: false,
          divider: true,
        },
        {
          text: "PO#",
          value: "poNumber",
          sortable: false,
          divider: true,
        },
        {
          text: "Project",
          value: "project",
          sortable: false,
          divider: true,
        },
        {
          text: "Dept",
          value: "department",
          sortable: false,
          divider: true,
        },
        {
          text: "Reff",
          value: "reference",
          sortable: false,
          divider: true,
        },
      ];
    },
  },
  mounted() {
    this.getInitialData();
  },
};
</script>

<style></style>
