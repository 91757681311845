<template>
  <v-dialog v-model="dialog" max-width="1000" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Link RR</span>
      </v-card-title>
      <v-card-text>
        <v-form lazy-validation v-model="valid" ref="form">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-row>
                <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                  <v-subheader>RR Number</v-subheader>
                </v-col>
                <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                  <v-autocomplete
                    dense
                    outlined
                    flat
                    background-color="white"
                    append-icon="mdi-magnify"
                    :items="deliveryOrders"
                    item-text="name"
                    return-object
                    v-model="rr"
                    :filter="filterObjectDo"
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ data.item.do_num }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-btn color="success" @click="addRR">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12" class="mt-2">
              <v-data-table
                :headers="headers"
                :items="items"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:item.no="{ index }">
                  {{ index + 1 }}
                </template>
                <template v-slot:item.invoiceNumber="{ item }">
                  <v-text-field
                    dense
                    outlined
                    flat
                    background-color="white"
                    v-model="item.invoiceNumber"
                  ></v-text-field>
                </template>
                <template v-slot:item.invoiceDate="{ item }">
                  <v-menu
                    v-model="item.menuDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        flat
                        dense
                        background-color="white"
                        v-model="item.invoiceDate"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="item.invoiceDate"
                      @input="item.menuDate = false"
                    ></v-date-picker>
                  </v-menu>
                </template>
                <template v-slot:item.totalAmount="{ item }">
                  <span>{{ formatPrice(item.totalAmount) }}</span>
                </template>
                <template v-slot:item.action="{ index, item }">
                  <v-btn icon @click="viewProduct(item.rrId)" color="primary" class="mr-2">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                  <v-btn icon @click="deleteRR(index)" color="red">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="close" class="mr-2">
          Close
        </v-btn>
        <v-btn color="primary" @click="checkJournal">
          review journal
        </v-btn>
      </v-card-actions>
    </v-card>
    <dialog-review-journal
      :dialog="dialogReview"
      :journals="journals"
      @save="submit"
      @close="closeReview"
    ></dialog-review-journal>
    <product-rr :dialog="dialogProduct" :items="products" @close="closeProduct"></product-rr>
  </v-dialog>
</template>

<script>
import DialogReviewJournal from "@/components/DialogReviewJournal";
import moment from "moment";
import ProductRr from "./ProductRr.vue";
export default {
  name: "dialog-link-RR",
  props: {
    dialog: Boolean,
    id: Number,
    deliveryOrders: Array,
  },
  components: {
    DialogReviewJournal,
    ProductRr,
  },
  computed: {
    total() {
      return this.items.map(x => x.totalAmount).reduce((x, y) => x + y);
    },
  },
  data() {
    return {
      valid: true,
      dialogReview: false,
      dialogProduct: false,
      menuInvoiceDate: false,
      rr: {},
      items: [],
      journals: [],
      products: [],
      headers: [
        {
          text: "No",
          value: "no",
          sortable: false,
          divider: true,
        },
        {
          text: "RR Number",
          value: "rrNumber",
          sortable: false,
          divider: true,
        },
        {
          text: "Old RR Number",
          value: "oldRrNumber",
          sortable: false,
          divider: true,
        },
        {
          text: "Invoice Number",
          value: "invoiceNumber",
          sortable: false,
          divider: true,
        },
        {
          text: "Invoice Date",
          value: "invoiceDate",
          sortable: false,
          divider: true,
        },
        {
          text: "Total",
          value: "totalAmount",
          sortable: false,
          divider: true,
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          divider: true,
        },
      ],
    };
  },
  methods: {
    checkJournal() {
      if (this.$refs.form.validate() && this.items.length > 0) {
        this.journals = [
          {
            accountName: `PERSEDIAAN KOMPONEN`,
            debit: this.total,
            credit: 0,
            debitIDR: 0,
            creditIDR: 0,
          },
          {
            accountName: `UANG MUKA PEMBELIAN`,
            debit: 0,
            credit: this.total,
            debitIDR: 0,
            creditIDR: 0,
          },
        ];
        this.dialogReview = true;
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "RR is required",
        });
      }
    },
    deleteRR(index) {
      this.items.splice(index, 1);
    },
    async addRR() {
      if (this.items.indexOf(this.rr) > -1) {
        this.$store.dispatch("toast", {
          type: "error",
          message: "RR sudah digunakan",
        });
      } else {
        await this.$store.dispatch("external/getTotalRr", this.rr.id).then(response => {
          this.items.push({
            rrId: this.rr.id,
            rrNumber: this.rr.name,
            oldRrNumber: this.rr.do_num,
            invoiceNumber: "",
            invoiceDate: moment().format("YYYY-MM-DD"),
            totalAmount: response.data,
            menuDate: false,
          });
        });
      }
    },
    closeProduct() {
      this.dialogProduct = false;
    },
    async viewProduct(id) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("external/getProductByRrId", id)
        .then(response => {
          this.products = response.data;
          this.$store.commit("SET_LOADER", false);
          this.dialogProduct = true;
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async submit() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("purchaseDownPayment/linkRr", { id: this.id, body: this.items })
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    close() {
      this.$emit("close");
    },
    closeReview() {
      this.dialogReview = false;
    },

    // eslint-disable-next-line no-unused-vars
    filterObjectDo(item, queryText, itemText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.do_num.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
